import React from "react";
import { ScatterChart } from "@tremor/react";

function ScatterChat({ data }) {
  const chartdata = data.map((item) => {
    const messageDate = new Date(item.fecha);
    const today = new Date();
    const daysSince = Math.floor((today - messageDate) / (1000 * 60 * 60 * 24));

    return {
      Mensaje:item.message,
      Sentimiento: item.sentiment_polarity,
      Subjetividad: item.sentiment_subjectivity!==0?item.sentiment_subjectivity:item.sentiment_subjectivity+0.1,
      Fecha: item.fecha,
      Días: daysSince,
    };
  });

  return (
    <div className="m-20">
      <ScatterChart
        className="h-80 mt-6 -ml-2"
        data={chartdata}
        category="Mensaje"
        x="Días"
        y="Sentimiento"
        size="Subjetividad"
        showOpacity={true}
        showLegend={false}
        minYValue={-1}
        maxYValue={1}
      />
    </div>
  );
}

export default ScatterChat;
