import React, { useState, useEffect, useCallback } from "react";
import ReactWordcloud from "react-wordcloud";
import LCTitle from "../components/LCTitle";

function Keywords() {
  const [keywords, setKeywords] = useState("Formica");
  const [words, setWords] = useState([]);

  const handleKeywordsChange = (e) => {
    const value = e.target.value;
    clearTimeout(handleKeywordsChange.timeoutId);
    handleKeywordsChange.timeoutId = setTimeout(() => {
      setKeywords(value);
    }, 1000);
  };

  const fetchData = useCallback(async (keyword) => {
    const url = `https://formicaback.vercel.app/keywords?keyword=${keyword}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      setWords(data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchData(keywords);
  }, [keywords, fetchData]);

  const options = {
    colors: ["#ff0000", "#e60000", "#cc0000", "#b30000", "#990000", "#800000"],
    enableTooltip: true,
    deterministic: true,
    fontFamily: "impact",
    fontSizes: [10, 80],
    fontStyle: "normal",
    fontWeight: "normal",
    padding: 1,
    rotations: 3,
    rotationAngles: [0, 0],
    scale: "sqrt",
    spiral: "archimedean",
    transitionDuration: 1000,
  };

  return (
    <div id="main-container" className="flex flex-col gap-4 px-48 w-full pb-20">
      <div
        id="top-container"
        className="flex flex-row  items-center justify-around w-full px-24 gap-10 rounded-xl"
      >
        <LCTitle title="Keywords" />
        <input
          type="text"
          onChange={handleKeywordsChange}
          className="h-9 rounded-md w-96 text-center"
        />
      </div>

      <div
        id="bottom-container"
        className="w-full py-32 mt-7 bg-white rounded-[60px] shadow-lg overflow-y-scroll"
      >
        <ReactWordcloud words={words} options={options} />
      </div>
    </div>
  );
}

export default Keywords;
