import React, { useState, useEffect } from "react";
import ScatterChat from "../components/ScatterChat";
import Donut from "../components/DonutChart";
import { supabaseClient } from "../supabaseClient";
import LCTitle from "../components/LCTitle";
import DateRangeP from "../components/DateRangeP";

function Sentiment() {
  const [data, setData] = React.useState([]);
  const [fecha, setFecha] = useState({
    from: new Date(Date.now() - 365 * 24 * 60 * 60 * 1000),
    to: new Date(),
  });

  

  const fetchSupabase = async () => {
    const { data, error } = await supabaseClient
      .from("messages")
      .select("*")
      .filter("fecha", "gt", fecha.from.toISOString())
      .filter("fecha", "lt", fecha.to.toISOString())
      .order("fecha", { ascending: true });

    if (error) console.log("error", error);
    else setData(data);
  };

  useEffect(() => {
    if (fecha.from && fecha.to) {
      fetchSupabase();
    }
  }, [fecha]);


  return (
    <>
      <div
        id="main-container"
        className="flex flex-col gap-4 px-48 w-full pb-20"
      >
        <div
          id="top-container"
          className="flex flex-row  items-center justify-around w-full px-24 gap-10 rounded-xl"
        >
          <div
            id="top-container"
            className="flex flex-row  items-center justify-around w-full px-24 gap-80 rounded-xl"
          >
            <LCTitle title="Sentiment" />
        
            <DateRangeP fecha={fecha} setFecha={setFecha} />
          </div>
        </div>

        {/*>>>>>>>>>>>>>>>>>>>>>>>>>><BOTTOM CONTAINER><<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/}
        <div
          id="bottom-container"
          className="w-full h-4/5 bg-white rounded-[60px] shadow-lg overflow-y-scroll"
        >
          <Donut data={data && data} />
          <ScatterChat data={data && data} />
        </div>
      </div>
    </>
  );
}

export default Sentiment;
