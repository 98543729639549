import React from "react";
import { Chart } from "react-google-charts";

function GeoChart({ data }) {
  const keyword = Object.keys(data[0])[1];
  
  const mappedData = data.map((item) => [item.geoName, item[keyword]]);

  return (
    <div>
      <Chart
        chartType="GeoChart"
        data={[["Estado", keyword], ...mappedData]} // Remove the curly braces
        options={{
          region: "MX",
          displayMode: "regions",
          resolution: "provinces",
          colorAxis: { colors: ["#ff0000", "#500000"] },
          width: "800px",
        }}
        legendToggle
      />
    </div>
  );
}

export default GeoChart;