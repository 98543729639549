import React, { useEffect, useState, useCallback } from "react";
import LineChart from "../components/LineChart";
import LCTitle from "../components/LCTitle";
import DateRangePTrends from "../components/DateRangePTrends";
import GeoChart from "../components/GeoChart";

function Trends() {
  const [keywords, setKeywords] = useState(["Formica"]);
  const [timeframe, setTimeframe] = useState("");
  const [data, setData] = useState();
  const [fecha, setFecha] = useState({
    from: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
    to: new Date(),
  });
  //FUNCTIONS

  const getChartData = useCallback(async () => {
    const url = `https://formicaback.vercel.app/trends?keywords=${keywords}&timeframe=${timeframe}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error(error);
    }
  }, [keywords, timeframe]);

  const fechaToTimeframe = (fecha) => {
    try {
      const from = fecha.from.toISOString().slice(0, 10);
      const to = fecha.to.toISOString().slice(0, 10);
      setTimeframe(`${from} ${to}`);
    } catch (error) {
      console.error(error);
      setTimeframe("2023-01-01 2023-07-09");
    }
  };

  const handleKeywordsChange = (e) => {
    const value = e.target.value;
    clearTimeout(handleKeywordsChange.timeoutId);
    handleKeywordsChange.timeoutId = setTimeout(() => {
      setKeywords(value);
    }, 1000);
  };
  useEffect(() => {
    getChartData();
  }, [getChartData]);

  useEffect(() => {
    getChartData();
  }, [keywords, timeframe, getChartData]);

  useEffect(() => {
    fechaToTimeframe(fecha);
  }, [fecha]);

  return (
    <>
      {/*>>>>>>>>>>>>>>>>>>>>>>>>>><MAIN CONTAINER><<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/}
      <div
        id="main-container"
        className="flex flex-col gap-4 px-48 w-full pb-20"
      >
        {/*>>>>>>>>>>>>>>>>>>>>>>>>>><TOP CONTAINER><<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/}
        <div
          id="top-container"
          className="flex flex-row  items-center justify-around w-full px-24 gap-10 rounded-xl"
        >
          <LCTitle title="Trends" />
          <div className="text-white">Keywords</div>
          <input
            type="text"
            onChange={handleKeywordsChange}
            className="h-9 rounded-md w-96 text-center"
          />

          <DateRangePTrends fecha={fecha} setFecha={setFecha} />
        </div>

        {/*>>>>>>>>>>>>>>>>>>>>>>>>>><BOTTOM CONTAINER><<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/}
        <div
          id="bottom-container"
          className="w-full h-4/5 bg-white rounded-[60px] shadow-lg overflow-y-scroll pt-10 mt-5"
        >
          {data && <LineChart data={data} />}
        </div>
        <div
          id="geo-container"
          className="flex flex-col justify-center items-center w-full py-10 bg-white rounded-[60px] shadow"
        >
          {data && <GeoChart data={data.ibr} />}
        </div>
      </div>
    </>
  );
}

export default Trends;
