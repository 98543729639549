import React from "react";
import { DonutChart } from "@tremor/react";

function Donut({ data }) {
  const positives = [];
  const negatives = [];
  const neutrals = [];

  data.forEach((item) => {
    if (item.sentiment_polarity > 0) {
      positives.push(item.sentiment_polarity*item.sentiment_subjectivity);
    } else if (item.sentiment_polarity < 0) {
      negatives.push(item.sentiment_polarity*item.sentiment_subjectivity);
    } else {
      neutrals.push(item.sentiment_polarity*item.sentiment_subjectivity);
    }
  });

  const positives_score = positives.reduce((a, b) => a + b, 0);
    const negatives_score = negatives.reduce((a, b) => a + b, 0)*-1;


  const donut_data = [
    { name: "Positivos", sales: positives_score / (positives_score+negatives_score) *100},
    { name: "Negativos", sales: negatives_score / (positives_score+negatives_score)*100  },
  ];

  return (
    <>
    <div className="relative flex justify-center items-center mt-14">
      <DonutChart
        data={donut_data}
        category="sales"
        index="name"
        colors={["gray", "red"]}
        showLabel={false}
        className="w-60"
      />
      <div className="text-5xl font-semibold absolute"> {parseInt(positives_score / (positives_score+negatives_score) *100)}</div>
    </div>
    </>
  );
}

export default Donut;