import { DateRangePicker, DateRangePickerItem } from "@tremor/react";

function DateRangePTrends({ fecha, setFecha }) {
  
  return (
    <DateRangePicker
      className="max-w-md mx-auto"
      value={fecha}
      onValueChange={setFecha}
      selectPlaceholder="Seleccionar"
      color="rose"
    >
      
      <DateRangePickerItem
        key="l7d"
        value="l7d"
        from={new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)}
        to={new Date(Date.now())}
      >
        Últimos 7 días
      </DateRangePickerItem>
      <DateRangePickerItem
        key="l15d"
        value="l15d"
        from={new Date(Date.now() - 15 * 24 * 60 * 60 * 1000)}
        to={new Date(Date.now())}
      >
        Últimos 15 días
      </DateRangePickerItem>
      <DateRangePickerItem
        key="l30d"
        value="l30d"
        from={new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)}
        to={new Date(Date.now())}
      >
        Últimos 30 días
      </DateRangePickerItem>
      <DateRangePickerItem
        key="l180d"
        value="l180d"
        from={new Date(Date.now() - 180 * 24 * 60 * 60 * 1000)}
        to={new Date(Date.now())}
      >
        Últimos 6 meses
      </DateRangePickerItem>
      <DateRangePickerItem
        key="l365d"
        value="l365d"
        from={new Date(Date.now() - 365 * 24 * 60 * 60 * 1000)}
        to={new Date(Date.now())}
      >
        Último año
      </DateRangePickerItem>
    </DateRangePicker>
  );
}

export default DateRangePTrends;
