import React from "react";

function FacebookButton({ facebook, setFacebook }) {
  const handleButtonClick = (e, state, setState) => {
    e.preventDefault();
    setState(!state);
  };

  return (
    <button
      onClick={(e) => handleButtonClick(e, facebook, setFacebook)}
      className={`text-white w-48 h-10 rounded-lg ${
        facebook ? "bg-formica" : "bg-fondo"
      } my-3`}
    >
      Facebook
    </button>
  );
}

export default FacebookButton;
