import { DateRangePicker, DateRangePickerItem } from "@tremor/react";

function DateRangeP({ fecha, setFecha }) {
  const currentDate = new Date();
  const currentDay = currentDate.getDay();
  const weekStart = new Date(
    currentDate.getTime() - (currentDay - 1) * 24 * 60 * 60 * 1000
  );
  const monthStart = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const yearStart = new Date(currentDate.getFullYear(), 0, 1);

  return (
    <DateRangePicker
      className="max-w-md mx-auto"
      value={fecha}
      onValueChange={setFecha}
      selectPlaceholder="Seleccionar"
      color="rose"
    >
      <DateRangePickerItem
        key="tdy"
        value="tdy"
        from={new Date(Date.now())}
        to={new Date(Date.now())}
      >
        Hoy
      </DateRangePickerItem>
      <DateRangePickerItem
        key="tw"
        value="tw"
        from={weekStart}
        to={new Date(Date.now())}
      >
        Esta Semana
      </DateRangePickerItem>
      <DateRangePickerItem
        key="tm"
        value="tm"
        from={monthStart}
        to={new Date(Date.now())}
      >
        Este Mes
      </DateRangePickerItem>
      <DateRangePickerItem
        key="ty"
        value="ty"
        from={yearStart}
        to={new Date(Date.now())}
      >
        Este Año
      </DateRangePickerItem>
      <DateRangePickerItem
        key="l7d"
        value="l7d"
        from={new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)}
        to={new Date(Date.now())}
      >
        Últimos 7 días
      </DateRangePickerItem>
      <DateRangePickerItem
        key="l15d"
        value="l15d"
        from={new Date(Date.now() - 15 * 24 * 60 * 60 * 1000)}
        to={new Date(Date.now())}
      >
        Últimos 15 días
      </DateRangePickerItem>
      <DateRangePickerItem
        key="l30d"
        value="l30d"
        from={new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)}
        to={new Date(Date.now())}
      >
        Últimos 30 días
      </DateRangePickerItem>
      <DateRangePickerItem
        key="l180d"
        value="l180d"
        from={new Date(Date.now() - 180 * 24 * 60 * 60 * 1000)}
        to={new Date(Date.now())}
      >
        Últimos 6 meses
      </DateRangePickerItem>
      <DateRangePickerItem
        key="l365d"
        value="l365d"
        from={new Date(Date.now() - 365 * 24 * 60 * 60 * 1000)}
        to={new Date(Date.now())}
      >
        Último año
      </DateRangePickerItem>
    </DateRangePicker>
  );
}

export default DateRangeP;
