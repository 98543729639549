import React from "react";

function InstagramButton({ instagram, setInstagram }) {
  const handleButtonClick = (e, state, setState) => {
    e.preventDefault();
    setState(!state);
  };

  return (
    <button
      onClick={(e) => handleButtonClick(e, instagram, setInstagram)}
      className={`text-white w-48 h-10 rounded-lg ${
        instagram ? "bg-formica" : "bg-fondo"
      } my-3`}
    >
      Instagram
    </button>
  );
}

export default InstagramButton;
