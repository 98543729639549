import React from "react";
import { Title, LineChart } from "@tremor/react";

function LineChrt({ data }) {
  const all_keys = Object.keys(data["iot"][0]);
  const categories = all_keys.slice(1);

  return (
    <div className="px-20">
      <Title>Interés a través del tiempo</Title>
      {data && (
        <LineChart
          className="my-20 h-72"
          data={data.iot}
          index="date"
          categories={categories}
          colors={[
            "red",
            "yellow",
            "green",
            "blue",
            "purple",
            "pink",
            "orange",
          ]}
          yAxisWidth={40}
          showAnimation={false}
          curveType="natural"
        />
      )}
    </div>
  );
}

export default LineChrt;
