import React from "react";

function LCTitle({ title }) {
  return (
    <div className="flex flex-row justify-center items-center gap-3 w-64 px-2">
      <div className="text-4xl font-semibold text-white">{title.toUpperCase()}</div>
    </div>
  );
}

export default LCTitle;
