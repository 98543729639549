import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { supabaseClient } from "../supabaseClient";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { data, error } = await supabaseClient.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      alert(error.message);
    } else if (data.user && data.user.email) {
      navigate("/TopPosts");
    }
  };

  return (
    <div className="flex flex-col justify-center items-center h-[80vh]">
      <div className="flex flex-col justify-center items-center bg-white w-[500px] h-[400px] rounded-3xl shadow-lg shadow-black">
        <form
          onSubmit={handleSubmit}
          className="flex flex-col justify-center items-center"
        >
          <div className="flex flex-col justify-center items-center gap-4">
            <h1 className="text-fondo text-4xl text-center font-bold">LOGIN</h1>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              className="border-black border rounded-full h-8 w-80 text-center mt-6"
              placeholder="Email"
            />
            <input
              type="password"
              id="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              placeholder="Password"
              className="border-black border rounded-full h-8 w-80 text-center mb-6"
            />

            <button type="submit" className="text-white bg-formica px-4 w-60 rounded-full py-2">
              Log in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
