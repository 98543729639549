import React, { useEffect, useState } from "react";
import LCTitle from "../components/LCTitle";
import FacebookButton from "../components/FacebookButton";
import InstagramButton from "../components/InstagramButton";
import DateRangeP from "../components/DateRangeP";
import { Title, LineChart } from "@tremor/react";

function Metrics() {
  //VARIABLES

  const [facebook, setFacebook] = React.useState(true);
  const [facebookData, setFacebookData] = React.useState({});
  const [instagram, setInstagram] = React.useState(true);
  const [instagramData, setInstagramData] = React.useState({});
  const [fecha, setFecha] = useState({
    from: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
    to: new Date(),
  });

  useEffect(() => {
    if (facebook) {
      const getFacebookData = async () => {
        try {
          const response = await fetch(
            `https://formicaback.vercel.app/fb_metrics?start_date=${
              fecha.from.toISOString().split("T")[0]
            }&end_date=${fecha.to.toISOString().split("T")[0]}`
          );

          const data = await response.json();
          setFacebookData(data);
        } catch (error) {
          console.error(error);
        }
      };
      getFacebookData();
    }
  }, [facebook, fecha]);

  useEffect(() => {
    if (instagram) {
      const getInstagramData = async () => {
        try {
          const response = await fetch(
            `https://formicaback.vercel.app/ig_metrics?start_date=${
              fecha.from.toISOString().split("T")[0]
            }&end_date=${fecha.to.toISOString().split("T")[0]}`
          );

          const data = await response.json();
          setInstagramData(data);
        } catch (error) {
          console.error(error);
        }
      };
      getInstagramData();
    }
  }, [instagram, fecha]);

  return (
    <>
      {/*>>>>>>>>>>>>>>>>>>>>>>>>>><MAIN CONTAINER><<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/}
      <div
        id="main-container"
        className="flex flex-col gap-4 px-48 w-full pb-20"
      >
        {/*>>>>>>>>>>>>>>>>>>>>>>>>>><TOP CONTAINER><<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/}
        <div
          id="top-container"
          className="flex flex-row  items-center justify-around w-full px-24 gap-10 rounded-xl"
        >
          <LCTitle title="Metrics" />
          <FacebookButton setFacebook={setFacebook} facebook={facebook} />
          <InstagramButton setInstagram={setInstagram} instagram={instagram} />
          <DateRangeP fecha={fecha} setFecha={setFecha} />
        </div>

        {/*>>>>>>>>>>>>>>>>>>>>>>>>>><RIGHT CONTAINER><<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/}
        <div id="bottom-container">
          {facebook && (
            <div className="flex flex-col gap-10 my-10">
              <div className="bg-white rounded-3xl p-14">
                <Title>Facebook Page Fans</Title>
                <LineChart
                  data={facebookData.page_fans}
                  index="fecha"
                  categories={["Fans"]}
                  colors={["blue", "sky", "cyan"]}
                  noDataText="Loading..."
                  yAxisWidth={60}
                  curveType="monotoneX"
                  minValue={217000}
                />
              </div>

              <div className="bg-white rounded-3xl p-14">
                <Title>Facebook Page Views</Title>
                <LineChart
                  data={facebookData.page_views}
                  index="fecha"
                  categories={[
                    "Views total",
                    "Views logged in total",
                    "Views logged in unique",
                  ]}
                  colors={["blue", "sky", "cyan"]}
                  noDataText="Loading..."
                  yAxisWidth={60}
                  curveType="monotoneX"
                  minValue={217000}
                />
              </div>

              <div className="bg-white rounded-3xl p-14">
                <Title>Facebook Page Posts</Title>
                <LineChart
                  data={facebookData.page_posts}
                  index="fecha"
                  categories={[
                    "Posts impressions",
                    "Posts impressions organic",
                    "Posts impressions paid",
                  ]}
                  colors={["blue", "sky", "cyan"]}
                  noDataText="Loading..."
                  yAxisWidth={60}
                  curveType="monotoneX"
                />
              </div>

              <div className="bg-white rounded-3xl p-14">
                <Title>Facebook Impressions</Title>
                <LineChart
                  data={facebookData.impressions}
                  index="fecha"
                  categories={[
                    "Impressions",
                    "Impressions paid",
                    "Impressions organic v2",
                  ]}
                  colors={["blue", "sky", "cyan"]}
                  noDataText="Loading..."
                  yAxisWidth={60}
                  curveType="monotoneX"
                />
              </div>

              <div className="bg-white rounded-3xl p-14">
                <Title>Facebook Reach</Title>
                <LineChart
                  data={facebookData.reach}
                  index="fecha"
                  categories={[
                    "Impressions unique",
                    "Impressions paid unique",
                    "Impressions organic unique v2",
                  ]}
                  colors={["blue", "sky", "cyan"]}
                  noDataText="Loading..."
                  yAxisWidth={60}
                  curveType="monotoneX"
                />
              </div>

              <div className="bg-white rounded-3xl p-14">
                <Title>Facebook Engagement</Title>
                <LineChart
                  data={facebookData.engagement}
                  index="fecha"
                  categories={[
                    "Engaged users",
                    "Post engagements",
                    "Consumptions",
                  ]}
                  colors={["blue", "sky", "cyan"]}
                  noDataText="Loading..."
                  yAxisWidth={60}
                  curveType="monotoneX"
                />
              </div>

              <div className="bg-white rounded-3xl p-14">
                <Title>Facebook Reactions</Title>
                <LineChart
                  data={facebookData.reactions}
                  index="fecha"
                  categories={[
                    "Actions post reactions like total",
                    "Actions post reactions love total",
                    "Actions post reactions wow total",
                    "Actions post reactions haha total",
                    "Actions post reactions sorry total",
                    "Actions post reactions anger total",
                  ]}
                  colors={["blue", "sky", "cyan"]}
                  noDataText="Loading..."
                  yAxisWidth={60}
                  curveType="monotoneX"
                />
              </div>

              <div className="bg-white rounded-3xl p-14">
                <Title>Facebook Video Views</Title>
                <LineChart
                  data={facebookData.video_views}
                  index="fecha"
                  categories={[
                    "Video views",
                    "Video views paid",
                    "Video views organic",
                  ]}
                  colors={["blue", "sky", "cyan"]}
                  noDataText="Loading..."
                  yAxisWidth={60}
                  curveType="monotoneX"
                />
              </div>
            </div>
          )}

          {instagram && (
            <div className="flex flex-col gap-10 my-10">
              <div className="bg-white rounded-3xl p-14">
                <Title>Instagram Followers Count</Title>
                <LineChart
                  data={instagramData.follower_count}
                  index="fecha"
                  categories={["Follower count"]}
                  colors={["pink"]}
                  noDataText="Loading..."
                  yAxisWidth={60}
                  curveType="monotoneX"
                  minValue={217000}
                />
              </div>

              <div className="bg-white rounded-3xl p-14">
                <Title>Instagram Impressions</Title>
                <LineChart
                  data={instagramData.impressions}
                  index="fecha"
                  categories={["Impressions"]}
                  colors={["pink"]}
                  noDataText="Loading..."
                  yAxisWidth={60}
                  curveType="monotoneX"
                  minValue={217000}
                />
              </div>

              <div className="bg-white rounded-3xl p-14">
                <Title>Instagram Profile Views</Title>
                <LineChart
                  data={instagramData.profile_views}
                  index="fecha"
                  categories={["Profile views"]}
                  colors={["pink"]}
                  noDataText="Loading..."
                  yAxisWidth={60}
                  curveType="monotoneX"
                  minValue={217000}
                />
              </div>

              <div className="bg-white rounded-3xl p-14">
                <Title>Instagram Reach</Title>
                <LineChart
                  data={instagramData.reach}
                  index="fecha"
                  categories={["Reach"]}
                  colors={["pink"]}
                  noDataText="Loading..."
                  yAxisWidth={60}
                  curveType="monotoneX"
                  minValue={217000}
                />
              </div>

              <div className="bg-white rounded-3xl p-14">
                <Title>Instagram Website Clicks</Title>
                <LineChart
                  data={instagramData.website_clicks}
                  index="fecha"
                  categories={["Website clicks"]}
                  colors={["pink"]}
                  noDataText="Loading..."
                  yAxisWidth={60}
                  curveType="monotoneX"
                  minValue={217000}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Metrics;
