import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/logo.png";
import { AiOutlineLogout } from "react-icons/ai";
import { supabaseClient } from "../supabaseClient";

export default function NavBar() {


  const handleSignOut = async () => {
    const { error } = await supabaseClient.auth.signOut();
    if (error) {
      console.log(error.message);
    } else {
      console.log("User signed out successfully");
    }
  };
  return (
    <>
      <nav
        id="navbar"
        className="flex flex-row px-52 justify-between h-20 items-center  my-4"
      >
        <div id="navbar-left" className="flex flex-row items-center">
          <img
            id="navbar-left-img"
            className="h-16 border-2 border-white rounded-full mr-5"
            src={logo}
            alt="Formica Logo"
          />
        </div>
        <div id="navbar-right" className="flex flex-row items-center">
          <NavLink
            to="/topposts"
            className="text-white mr-5 text-base font-light cursor-pointer hover:text-formica"
          >
            TOP POSTS
          </NavLink>
          <NavLink
            to="/mentions"
            className="text-white mr-5 text-base font-light cursor-pointer hover:text-formica"
          >
            MENTIONS
          </NavLink>
          <NavLink
            to="/sentiment"
            className="text-white mr-5 text-base font-light cursor-pointer hover:text-formica"
          >
            SENTIMENT
          </NavLink>
          <NavLink
            to="/trends"
            className="text-white mr-5 text-base font-light cursor-pointer hover:text-formica"
          >
            TRENDS
          </NavLink>
          <NavLink
            to="/keywords"
            className="text-white mr-5 text-base font-light cursor-pointer hover:text-formica"
          >
            KEYWORDS
          </NavLink>
          <NavLink
            to="/metrics"
            className="text-white mr-5 text-base font-light cursor-pointer hover:text-formica"
          >
            METRICS
          </NavLink>
          <NavLink
            to="/demographics"
            className="text-white mr-5 text-base font-light cursor-pointer hover:text-formica"
          >
            DEMOGRAPHICS
          </NavLink>
          <NavLink
            to="/login"
            className="text-white mr-5 text-base font-light cursor-pointer hover:text-formica"
            onClick={handleSignOut}
          >
            <AiOutlineLogout
              size={20}
              color={"black"}
              className="w-7 h-7 bg-formica rounded-full"
            />
          </NavLink>
        </div>
      </nav>
    </>
  );
}
