import React, { useEffect, useState } from "react";
import LCTitle from "../components/LCTitle";
import FacebookButton from "../components/FacebookButton";
import InstagramButton from "../components/InstagramButton";
import SocialMediaCard from "../components/SocialMediaCard";
import { supabaseClient } from "../supabaseClient";
import DateRangeP from "../components/DateRangeP";

function TopPosts() {
  //VARIABLES

  const [facebook, setFacebook] = React.useState(true);
  const [instagram, setInstagram] = React.useState(true);
  const [posts, setPosts] = useState([]);
  const [redes, setRedes] = useState(["facebook", "instagram"]);
  const [fecha, setFecha] = useState({
    from: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
    to: new Date(),
  });

  useEffect(() => {
    function redes() {
      if (facebook === true && instagram === true) {
        setRedes(["facebook", "instagram"]);
      } else if (facebook === true && instagram === false) {
        setRedes(["facebook"]);
      } else if (instagram === true && facebook === false) {
        setRedes(["instagram"]);
      } else {
        setRedes([]);
      }
    }
    redes();
  }, [facebook, instagram]);

  //FUNCTIONS

  const fetchSupabase = async () => {
    const { data, error } = await supabaseClient
      .from("posts")
      .select("*")
      .filter("fecha", "gt", fecha.from.toISOString())
      .filter("fecha", "lt", fecha.to.toISOString())
      .in("red", redes)
      .order("likes", { ascending: false });
    if (error) console.log("error", error);
    else setPosts(data);
  };

  //USE EFFECTS
  useEffect(() => {
    if (fecha.from && fecha.to) {
      fetchSupabase();
    }
  }, [redes, fecha]);

  useEffect(() => {
    fetch("https://formicaback.vercel.app/facebook_posts")
      .then((res) => res.json())
      .then(() => console.log("Facebook updated"));
  }, []);

  useEffect(() => {
    fetch("https://formicaback.vercel.app/instagram_posts")
      .then((res) => res.json())
      .then(() => console.log("Instagram updated"));
  }, []);

  //RETURN
  return (
    <>
      {/*>>>>>>>>>>>>>>>>>>>>>>>>>><MAIN CONTAINER><<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/}
      <div
        id="main-container"
        className="flex flex-col gap-4 px-48 w-full pb-20"
      >
        {/*>>>>>>>>>>>>>>>>>>>>>>>>>><TOP CONTAINER><<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/}
        <div
          id="top-container"
          className="flex flex-row  items-center justify-around w-full px-24 gap-10 rounded-xl"
        >
          <LCTitle title="Top Posts" />
          <FacebookButton setFacebook={setFacebook} facebook={facebook} />
          <InstagramButton setInstagram={setInstagram} instagram={instagram} />
          <DateRangeP fecha={fecha} setFecha={setFecha} />
        </div>

        {/*>>>>>>>>>>>>>>>>>>>>>>>>>><RIGHT CONTAINER><<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/}
        <div
          id="bottom-container"
          className="w-full h-4/5 bg-white rounded-[60px] shadow-lg overflow-y-scroll"
        >
          <div className="flex flex-wrap justify-center min-w-[400px] py-2 pt-12">
            {posts &&
              posts.map((post, index) => {
                return (
                  <SocialMediaCard
                    key={index}
                    index={index + 1}
                    fecha={post.fecha_str}
                    likes={post.likes}
                    imagen={post.imagen}
                    texto={post.texto}
                    color={post.color}
                    url={post.url}
                    media_type={post.media_type}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}

export default TopPosts;
