import React, { useState, useEffect } from "react";
import LCTitle from "../components/LCTitle";
import { DonutChart } from "@tremor/react";
import { BarChart } from "@tremor/react";

function Demographics() {
  const [gender, setGender] = useState([]);
  const [genderAge, setGenderAge] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const url = `https://formicaback.vercel.app/demographics`;
      try {
        const response = await fetch(url);
        const data = await response.json();
        setGender(data.gender);
        setGenderAge(data.gender_age);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);


  const dataFormatter = (number) => {
    return Intl.NumberFormat("us").format(number).toString();
  };


  return (
    <div id="main-container" className="flex flex-col gap-4 px-48 w-full pb-20">
      <div
        id="top-container"
        className="flex flex-row  items-center justify-around w-full px-24 gap-10 rounded-xl"
      >
        <LCTitle title="Demographics" />
        <div className="w-3/6"></div>
      </div>

      <div
        id="bottom-container"
        className="w-full py-6 mt-7 bg-white rounded-[60px] shadow-lg"
      >
        <div className="flex flex-col justify-center items-center w-full h-full">
          <div className="flex flex-row w-4/6     ">
            <div className="w-1/2 h-96 flex justify-center items-center">
              <div className="relative w-72 h-72 flex justify-center items-center">
                <div
                  className="absolute h-48 w-48 rounded-full bg-cover"
                  style={{
                    backgroundImage: `url("https://cdn.midjourney.com/72702343-ac6c-4ff1-8a4a-591e50953993/0_0.png")`,
                  }}
                ></div>
                <DonutChart
                  className="h-full w-full"
                  data={gender}
                  category="value"
                  index="gender"
                  colors={["red", "gray"]}
                  noDataText="Loading..."
                  showLabel={false}
                />
              </div>
            </div>
            <div className="w-1/2 h-96 flex justify-center items-center">
              <div className="relative w-72 h-72 flex justify-center items-center">
                <div
                  className="absolute h-48 w-48 rounded-full bg-cover"
                  style={{
                    backgroundImage: `url("https://cdn.midjourney.com/2fafe310-a5b5-499c-bb7e-1400e016771f/0_0_384_N.webp")`,
                  }}
                ></div>
                <DonutChart
                  className="h-full w-full"
                  data={gender}
                  category="value"
                  index="gender"
                  colors={["gray", "red"]}
                  noDataText="Loading..."
                  showLabel={false}
                />
              </div>
            </div>
          </div>
          <div className="w-4/6 h-full">
            <BarChart
              className="mt-6"
              data={genderAge}
              index="edad"
              categories={["valor"]}
              colors={["red"]}
              valueFormatter={dataFormatter}
              yAxisWidth={48}
              noDataText="Loading..."
             showLegend={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Demographics;
