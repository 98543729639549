import React from "react";
import { FaHeart } from "react-icons/fa";

const socialMediaCard = (props) => {
  return (
    <a href={props.url} target="_blank" rel="noopener noreferrer">
      <div className="w-80 bg-gray-200 rounded-3xl mx-6 my-5">
        <div className="flex flex-row items-center bg-black rounded-t-3xl p-3">
          <div className="w-7 h-7 rounded-full bg-white text-center ml-7 leading-7">
            {props.index}
          </div>
          <div className="leading-7 ml-7 text-white self-center pr-4">
            {props.fecha}
          </div>
          <FaHeart color="red" />
          <div className="self-center text-white pl-2">{props.likes}</div>
        </div>
        <div
          className="h-1"
          style={{ backgroundColor: `${props.color}` }}
        ></div>
        <div className="card-image">
          {props.media_type === "video" ? (
            <video src={props.imagen} autoPlay muted loop />
          ) : (
            <img src={props.imagen} alt="Social media post" />
          )}
        </div>
        <div className="text-center p-7">{props.texto}</div>
      </div>
    </a>
  );
};

export default socialMediaCard;
