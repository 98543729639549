import "./App.css";
import NavBar from "./components/NavBar";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import TopPosts from "./paginas/TopPosts";
import Mentions from "./paginas/Mentions";
import Trends from "./paginas/Trends";
import Keywords from "./paginas/Keywords";
import Sentiment from "./paginas/Sentiment";
import Metrics from "./paginas/Metrics";
import Demographics from "./paginas/Demographics";
import PrivateRoutes from "./paginas/PrivateRoutes";
import Login from "./paginas/Login";

function App() {
  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route element={<TopPosts />} path="/" exact />
          <Route element={<TopPosts />} path="/TopPosts" />
          <Route element={<Mentions />} path="/Mentions" />
          <Route element={<Sentiment />} path="/Sentiment" />
          <Route element={<Trends />} path="/Trends" />
          <Route element={<Keywords />} path="/Keywords" />
          <Route element={<Metrics />} path="/Metrics" />
          <Route element={<Demographics />} path="/Demographics" />
        </Route>
        <Route element={<Login />} path="/login" />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
